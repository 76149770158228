import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { getPilByCode } from "../../state/actions/PilActions";
import { getProducto, saleProduct } from "../../state/actions/ProductActions";
import useStylesMain from "../../styles/useStylesMain";
import makeStyles from "./styles";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

const DetailsProductUser = ({ idProduct, setOpen }) => {
  const [dateFrom, setDateFrom] = useState(new Date());
  const [cantidad, setCantidad] = useState(1);
  const [code, setCode] = useState("");
  const [name, setName] = useState("");
  const [productoSeleccionado, setProductoSeleccionado] = useState({
    id: 0,
    name: "",
    description: "",
    stock: 0,
    stockMax: 0,
    stockMin: 0,
    brandId: 0,
    priceSale: 0.0,
    pricePurchase: 0.0,
    imageGeneral: null,
    imagenTemporal: "",
    image: "",
    brandName: "",
    isInKg: false,
    codeCategory: "",
    dateWork: new Date(),
  });

  const [{}, setPil] = useState({
    id: 0,
    name: "",
    code: "",
    milk: 0,
    soy: 0,
  });

  useEffect(() => {
    const getProductoAsync = async () => {
      const response = await getProducto(idProduct);
      setProductoSeleccionado(response.data);

      let fotoPerfil = response.data.imageGeneral;
      const nuevoFile =
        "data:image/" + fotoPerfil.extension + ";base64," + fotoPerfil.data;
      productoSeleccionado.imagenTemporal = nuevoFile;
      setProductoSeleccionado((anterior) => ({
        ...anterior,
        imagenTemporal: nuevoFile,
      }));
    };
    getProductoAsync();
  }, []);

  const agregarCarrito = async () => {
    const item = {
      id: idProduct,
      cantidad: cantidad,
      code: code,
      price: cantidad * productoSeleccionado.priceSale,
      priceExceed: 0,
      cantidadExceed: 0,
      dateSale: dateFrom,
    };

    saleProduct(item).then((response) => {
      setOpen(false);
    });
  };

  const handleChange = (e) => {
    setCode(e.target.value);
  };

  const setCantidadEvent = (e) => {
    setCantidad(e.target.value);
  };

  const changeData = (kg, stock) => {
    let usd = kg ? " (Kg) " : " (uds)";
    return stock + usd;
  };

  const handleKey = (e) => {
    if (e.key === "Enter") {
      const getPilObject = async () => {
        const response = await getPilByCode(code, idProduct);
        setPil(response.data);
        setName(response.data.name);
      };

      getPilObject();
    }
  };

  const searchAssociate = async () => {
    const getPilObject = async () => {
      const response = await getPilByCode(code, idProduct);
      setPil(response.data);
      setName(response.data.name);
    };

    getPilObject();
  };

  const classesMain = useStylesMain();
  const classes = makeStyles();

  return (
    <Container>
      <Typography variant="h4" className={classesMain.text_title}>
        {productoSeleccionado.name}
      </Typography>

      <Grid container spacing={2}>
        <Grid item md={6}>
          <Typography className={classes.text_detalleS}>
            PRECIO: {productoSeleccionado.priceSale} Bs
          </Typography>
          <Typography className={classes.text_detalleS}>
            DISPONIBLES: {changeData(productoSeleccionado.isInKg, productoSeleccionado.stock)}
          </Typography>
          <Typography className={classes.text_detalleS}>
            MARCA: {productoSeleccionado.brandName}
          </Typography>
          <Typography className={classes.text_detalleS}>
            DESCRIPCIÓN:
          </Typography>
          <Typography className={classes.text_detalleS}>
            {productoSeleccionado.description}
          </Typography>
        </Grid>
      </Grid>
      <Divider className={classes.dividerS} />

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            label="Código Socio"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            name="code"
            className={classesMain.gridmb}
            value={code}
            onChange={handleChange}
            onKeyDown={handleKey}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            onClick={searchAssociate}
            className={classes.button}
            fullWidth
          >
            Buscar socio
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <TextField
            label="Socio"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            name="name"
            className={classesMain.gridmb}
            value={name}
            disabled
            fullWidth
          />
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <KeyboardDatePicker
              value={dateFrom}
              onChange={setDateFrom}
              margin="normal"
              id="fecha-publicacion-id"
              label="Fecha de venta"
              format="dd/MM/yyyy"
              KeyboardButtonProps={{ "aria-label": "change date" }}
              fullWidth
            />
          </MuiPickersUtilsProvider>
        </Grid> */}
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            label="Cantidad"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            className={classesMain.gridmb}
            value={cantidad}
            onChange={setCantidadEvent}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={agregarCarrito}
            className={classes.button}
            fullWidth
          >
            Agregar a Carrito
          </Button>
        </Grid>
      </Grid>
      <Divider className={classes.dividerS} />
    </Container>
  );
};

export default DetailsProductUser;
