import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Icon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import React, { useEffect, useState } from "react";
import {
  eliminarProducto,
  getProductos,
} from "../../state/actions/ProductActions";
import useStylesMain from "../../styles/useStylesMain";
import AddIncrease from "./AddIncrease";
import AddMerge from "./AddMerge";
import AddPrice from "./AddPrice";
import AddProduct from "./AddProduct";
import EditProduct from "./EditProduct";
import makeStyles from "./styles";
import { useStateValue } from "../../state/store";

const Products = () => {
  const [{ sesionUsuario }, dispatch] = useStateValue();
  const [open, setOpen] = useState(false);
  const [openMerge, setOpenMerge] = useState(false);
  const [openPrice, setOpenPrice] = useState(false);
  const [openIncrease, setOpenIncrease] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [idProduct, setIdProduct] = useState("");

  const handleCloseUpdate = () => {
    setOpenUpdate(false);
  };

  const handleCloseAdd = () => {
    setOpen(false);
  };

  const handleClosePrice = () => {
    setOpenPrice(false);
  };

  const handleCloseMerge = () => {
    setOpenMerge(false);
  };

  const handleCloseIncrease = () => {
    setOpenIncrease(false);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const [requestProductos, setRequestProductos] = useState({
    pageIndex: 1,
    pageSize: 60,
    search: "",
  });

  const [paginadorProductos, setPaginadorProductos] = useState({
    count: 0,
    pageIndex: 0,
    pageSize: 0,
    pageCount: 0,
    data: [],
  });

  const handleChange = (event, value) => {
    setRequestProductos((anterior) => ({
      ...anterior,
      pageIndex: value,
    }));
  };

  useEffect(() => {
    const getListaProductos = async () => {
      const response = await getProductos({
        ...requestProductos,
        category: "",
      });
      setPaginadorProductos(response.data);
    };

    getListaProductos();
  }, [requestProductos]);

  const classes = makeStyles();
  const classesMain = useStylesMain();

  const agregarProducto = () => {
    setOpen(true);
  };

  const editaProducto = (id) => {
    setIdProduct(id);
    setOpenUpdate(true);
  };

  const refreshList = () => {
    const getListaProductos = async () => {
      const response = await getProductos({
        ...requestProductos,
        category: "",
      });
      setPaginadorProductos(response.data);
    };

    getListaProductos();
  };

  const removerProducto = (id) => {
    setOpenDelete(true);
    setIdProduct(id);
  };

  const addMerge = (id) => {
    setOpenMerge(true);
    setIdProduct(id);
  };

  const addPrice = (id) => {
    setOpenPrice(true);
    setIdProduct(id);
  };
  const addIncrease = (id) => {
    setOpenIncrease(true);
    setIdProduct(id);
  };
  const deleteProduct = () => {
    eliminarProducto(idProduct).then((response) => {
      const getListaProductos = async () => {
        const responsed = await getProductos({
          ...requestProductos,
          category: "",
        });
        setPaginadorProductos(responsed.data);
      };

      getListaProductos();
    });

    setOpenDelete(false);
  };

  return (
    <Container className={classesMain.containermt}>
      <Grid container>
        <Grid item lg={6} sm={6} xs={12}>
          <Typography
            component={"span"}
            variant="h4"
            className={classesMain.text_title}
          >
            PRODUCTOS
          </Typography>
        </Grid>
        <Grid item lg={6} sm={6} xs={12}>
          <Button
            variant="contained"
            color="secondary"
            className={classes.buttonAgregar}
            onClick={agregarProducto}
          >
            <Icon>add</Icon>
            NUEVO PRODUCTO
          </Button>
        </Grid>
      </Grid>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>NOMBRE</TableCell>
              <TableCell>PRECIO</TableCell>
              <TableCell>MARCA</TableCell>
              <TableCell>CATEGORIA</TableCell>
              <TableCell>STOCK</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginadorProductos.data.map((producto) => (
              <TableRow key={producto.id}>
                <TableCell>{producto.name}</TableCell>
                <TableCell>{producto.priceSale}</TableCell>
                <TableCell>{producto.brandName}</TableCell>
                <TableCell>{producto.categoryName}</TableCell>
                <TableCell>{producto.stock}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => editaProducto(producto.id)}
                  >
                    <Icon>edit</Icon>
                  </Button>
                  {sesionUsuario.usuario.admin && <Button
                    onClick={() => removerProducto(producto.id)}
                    variant="contained"
                    color="secondary"
                  >
                    <Icon>delete</Icon>
                  </Button>
                  }
                  <Tooltip title="Agregar sobras">
                    <Button
                      onClick={() => addIncrease(producto.id)}
                      variant="contained"
                      color="primary"
                    >
                      <Icon>add</Icon>
                    </Button>
                  </Tooltip>
                  <Tooltip title="Mermas">
                    <Button
                      onClick={() => addMerge(producto.id)}
                      variant="contained"
                      color="secondary"
                    >
                      <Icon>remove</Icon>
                    </Button>
                  </Tooltip>
                  <Tooltip title="Establecer precio">
                    <Button
                      onClick={() => addPrice(producto.id)}
                      variant="contained"
                      color="secondary"
                    >
                      <Icon>attach_money</Icon>
                    </Button>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        count={paginadorProductos.pageCount}
        page={paginadorProductos.pageIndex}
        onChange={handleChange}
      />

      <Dialog
        open={open}
        onClose={handleCloseAdd}
        aria-labelledby="form-dialog-title"
      >
        <AddProduct setOpen={setOpen} refreshList={refreshList}></AddProduct>

        <DialogActions>
          <Button onClick={handleCloseAdd} fullWidth color="secondary" variant="contained">
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openMerge}
        onClose={handleCloseMerge}
        aria-labelledby="form-dialog-title"
      >
        <AddMerge setOpenMerge={setOpenMerge} idProduct={idProduct} refreshList={refreshList}></AddMerge>

        <DialogActions>
          <Button
            onClick={handleCloseMerge}
            color="secondary"
            variant="contained"
            fullWidth
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openPrice}
        onClose={handleClosePrice}
        aria-labelledby="form-dialog-title"
      >
        <AddPrice setOpenPrice={setOpenPrice} idProduct={idProduct} refreshList={refreshList}></AddPrice>

        <DialogActions>
          <Button
            onClick={handleClosePrice}
            color="secondary"
            variant="contained"
            fullWidth
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openIncrease}
        onClose={handleCloseIncrease}
        aria-labelledby="form-dialog-title"
      >
        <AddIncrease setOpenIncrease={setOpenIncrease} idProduct={idProduct} refreshList={refreshList}></AddIncrease>

        <DialogActions>
          <Button
            onClick={handleCloseIncrease}
            color="secondary"
            variant="contained"
            fullWidth
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openUpdate}
        onClose={handleCloseUpdate}
        aria-labelledby="form-dialog-title"
      >
        <EditProduct
          setOpenUpdate={setOpenUpdate}
          refreshList={refreshList}
          idProduct={idProduct}
        ></EditProduct>

        <DialogActions>
          <Button
            onClick={handleCloseUpdate}
            color="secondary"
            variant="contained"
            fullWidth
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Eliminar producto</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Esta seguro de eliminar el producto?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={deleteProduct} color="primary" fullWidth variant="contained">
            Eliminar
          </Button>
          <Button
            onClick={handleCloseDelete}
            color="secondary"
            autoFocus
            variant="contained"
            fullWidth
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Products;
