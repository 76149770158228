import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Icon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import React, { useEffect, useState } from "react";
import { deletePil, getPil } from "../../state/actions/PilActions";
import useStylesMain from "../../styles/useStylesMain";
import AddPil from "./AddPil";
import makeStyles from "../Product/styles";
import EditPil from "./EditPil";
import { useStateValue } from "../../state/store";

const Pil = () => {
  const [{ sesionUsuario }, dispatch] = useStateValue();
  const [open, setOpen] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [idProduct, setIdProduct] = useState("");

  const handleCloseAdd = () => {
    setOpen(false);
  };

  const handleCloseUpdate = () => {
    setOpenUpdate(false);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const [requestProductos, setRequestProductos] = useState({
    pageIndex: 1,
    pageSize: 20,
    search: "",
  });

  const [paginadorProductos, setPaginadorProductos] = useState({
    count: 0,
    pageIndex: 0,
    pageSize: 0,
    pageCount: 0,
    data: [],
  });

  const handleChange = (event, value) => {
    setRequestProductos((anterior) => ({
      ...anterior,
      pageIndex: value,
    }));
  };

  useEffect(() => {
    const getListaProductos = async () => {
      const response = await getPil(requestProductos);
      setPaginadorProductos(response.data);
    };

    getListaProductos();
  }, [requestProductos]);

  const classes = makeStyles();
  const classesMain = useStylesMain();

  const agregarProducto = () => {
    setOpen(true);
  };

  const refreshList = () => {
    const getListaProductos = async () => {
      const response = await getPil(requestProductos);
      setPaginadorProductos(response.data);
    };

    getListaProductos();
  };

  const deleteProduct = () => {
    deletePil(idProduct).then((response) => {
      const getListaProductos = async () => {
        const response = await getPil(requestProductos);
        setPaginadorProductos(response.data);
      };

      getListaProductos();
    });

    setOpenDelete(false);
  };

  const editaProducto = (id) => {
    setIdProduct(id);
    setOpenUpdate(true);
  };

  const removerProducto = (id) => {
    setOpenDelete(true);
    setIdProduct(id);
  };

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setRequestProductos((prev) => ({
      ...prev,
      search: value,
      pageIndex: 1,
    }));
  };

  return (
    <Container className={classesMain.containermt}>
      <Typography
        component={"span"}
        variant="h4"
        className={classesMain.text_title}
      >
        CUPOS DE LECHE
      </Typography>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="right" lg={6} sm={6} xs={12}>
                <TextField
                  label="Buscar por codigo"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  onChange={handleSearchChange}
                />
              </TableCell>
              <TableCell lg={6} sm={6} xs={12}>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.buttonAgregar}
                  onClick={agregarProducto}
                >
                  <Icon>add</Icon>
                  AGREGAR CUPO DE LECHE
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>

        </Table>
      </TableContainer>





      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>NOMBRE</TableCell>
              <TableCell>CODIGO</TableCell>
              <TableCell>CUPO LECHE</TableCell>
              <TableCell>PORCENTAJE</TableCell>
              <TableCell>GRUPO</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginadorProductos.data.map((producto) => (
              <TableRow key={producto.id}>
                <TableCell>{producto.name}</TableCell>
                <TableCell>{producto.code}</TableCell>
                <TableCell>{producto.milk} Litros</TableCell>
                <TableCell>{producto.percentage} %</TableCell>
                <TableCell>{producto.group} </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => editaProducto(producto.id)}
                  >
                    <Icon>edit</Icon>
                  </Button>
                  {sesionUsuario.usuario.admin && <Button
                    onClick={() => removerProducto(producto.id)}
                    variant="contained"
                    color="secondary"
                  >
                    <Icon>delete</Icon>
                  </Button>
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        count={paginadorProductos.pageCount}
        page={paginadorProductos.pageIndex}
        onChange={handleChange}
      />

      <Dialog
        open={open}
        onClose={handleCloseAdd}
        aria-labelledby="form-dialog-title"

      >
        <AddPil setOpen={setOpen} refreshList={refreshList}></AddPil>

        <DialogActions>
          <Button onClick={handleCloseAdd} color="secondary" fullWidth variant="contained">
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openUpdate}
        onClose={handleCloseUpdate}
        aria-labelledby="form-dialog-title"
      >
        <EditPil
          setOpenUpdate={setOpenUpdate}
          refreshList={refreshList}
          idProduct={idProduct}
        ></EditPil>

        <DialogActions>
          <Button
            onClick={handleCloseUpdate}
            color="secondary"
            fullWidth
            variant="contained"
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Eliminar</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Esta seguro de eliminar ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={deleteProduct} color="primary" fullWidth variant="contained">
            Eliminar
          </Button>
          <Button
            onClick={handleCloseDelete}
            color="secondary"
            fullWidth
            autoFocus
            variant="contained"
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Pil;
