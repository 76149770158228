import {
  Button,
  Checkbox,
  Container,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import useStylesMain from "../../styles/useStylesMain";
import {
  getAllDrivers,
  getAllProducts,
  getAllProviders,
  getDrivers,
  registerBuys,
} from "../../state/actions/ShoppingActions";
import { Autocomplete } from "@material-ui/lab";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

const AddShopping = ({ setOpen, refreshList }) => {
  const [dateFrom, setDateFrom] = useState(new Date());
  const [inputProduct, setInputProduct] = useState(null);
  const [price, setPrice] = useState(0.0);
  const [show, setShow] = useState(false);
  const [inputProvider, setInputProvider] = useState(null);
  const [inputDriver, setInputDriver] = useState(null);
  const [products, setProducts] = useState({
    data: [],
  });

  const [providers, setProviders] = useState({
    data: [],
  });

  const [drivers, setDrivers] = useState({
    data: [],
  });

  const [selectedValues, setSelectedValues] = useState([]);

  useEffect(() => {
    const getListProducts = async () => {
      const response = await getAllProducts();
      setProducts(response.data);
    };

    getListProducts();

    const getListProviders = async () => {
      const response = await getAllProviders();
      setProviders(response.data);
    };

    getListProviders();

    const getListDrivers = async () => {
      const response = await getAllDrivers();
      setDrivers(response.data);
    };

    getListDrivers();
  }, []);

  const [producto, setProducto] = useState({
    product: 0,
    quantity: 0.0,
    provider: 0,
    driver: 0,
    advance: 0.0,
    total: 0.0,
    details: "",
    priceBuy: 0.0,
    priceSale: 0.0,
    group: 0,
    unit: 0.0,
    dateWork: new Date(),
  });

  const guardarProducto = async () => {
    producto.provider = inputProvider.providerId;
    producto.driver = inputDriver.id;
    producto.product = inputProduct.productId;
    producto.unit = price;
    producto.dateWork = dateFrom;
    producto.group = selectedValues.toString();

    registerBuys(producto).then((response) => {
      refreshList();
    });

    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProducto((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleChangePrice = (e) => {
    setPrice(e.target.value);
    const valueData = parseFloat(e.target.value);
    if (valueData > 0) {
      producto.total = producto.quantity * valueData;
    } else {
      producto.total = producto.quantity;
    }
  };

  

  const handleChangeSelect = (event) => {
    const value = event.target.value;
    setSelectedValues(typeof value === 'string' ? value.split(',') : value);
  };
  const classesMain = useStylesMain();

  return (
    <Container className={classesMain.containermt}>
      <Grid container justify="center">
        <Grid item sm={8} xs={12}>
          <Typography variant="h5" className={classesMain.text_title}>
            AGREGAR PEDIDO
          </Typography>
          <form
            onSubmit={(e) => e.preventDefault()}
            className={classesMain.form}
          >
            <Autocomplete
              options={providers}
              getOptionLabel={(option) => option.name}
              id="controlled-demo"
              value={inputProvider}
              onChange={(event, newValue) => {
                setInputProvider(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Proveedores"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
            <br></br>

            <Autocomplete
              options={products}
              getOptionLabel={(option) =>
                option.name + " " + "(" + option.marca + ")"
              }
              id="controlled-demo"
              value={inputProduct}
              onChange={(event, newValue) => {
                setInputProduct(newValue);
                if (newValue && newValue.code == "CA") {
                  setShow(true);
                } else {
                  setShow(false);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Productos"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
            <br></br>

            <Autocomplete
              options={drivers}
              getOptionLabel={(option) => option.name}
              id="controlled-demo"
              value={inputDriver}
              onChange={(event, newValue) => {
                setInputDriver(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Conductores"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
            <br></br>
            {/* <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <KeyboardDatePicker
                value={dateFrom}
                onChange={setDateFrom}
                margin="normal"
                id="fecha-publicacion-id"
                fullWidth
                label="Fecha de compra"
                format="dd/MM/yyyy"
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
            <br></br>
            <br></br> */}

            {show && (
             <FormControl fullWidth>
             <InputLabel id="demo-multiple-checkbox-label">Seleccione Grupos</InputLabel>
             <br></br>
             <Select
               labelId="demo-multiple-checkbox-label"
               id="demo-multiple-checkbox"
               multiple
               value={selectedValues}
               onChange={handleChangeSelect}
               input={<OutlinedInput label="Seleccione Grupos" />}
               renderValue={(selected) => selected.join(', ')}
             >
               {[1, 2, 3, 4].map((number) => (
                 <MenuItem key={number} value={number}>
                   <Checkbox checked={selectedValues.indexOf(number) > -1} />
                   <ListItemText primary={number} />
                 </MenuItem>
               ))}
             </Select>
             <br></br>
           </FormControl>
            )}

            <TextField
              label="Cantidad"
              variant="outlined"
              fullWidth
              className={classesMain.gridmb}
              InputLabelProps={{
                shrink: true,
              }}
              name="quantity"
              value={producto.quantity}
              onChange={handleChange}
            />

            <TextField
              label="Precio unitario"
              variant="outlined"
              fullWidth
              className={classesMain.gridmb}
              InputLabelProps={{
                shrink: true,
              }}
              name="price"
              value={price}
              onChange={handleChangePrice}
            />

            <TextField
              label="Detalles"
              variant="outlined"
              fullWidth
              className={classesMain.gridmb}
              InputLabelProps={{
                shrink: true,
              }}
              name="details"
              value={producto.details}
              onChange={handleChange}
            />

            <TextField
              label="Monto adelanto"
              variant="outlined"
              fullWidth
              className={classesMain.gridmb}
              InputLabelProps={{
                shrink: true,
              }}
              name="advance"
              value={producto.advance}
              onChange={handleChange}
            />
            <TextField
              label="Monto total"
              variant="outlined"
              fullWidth
              className={classesMain.gridmb}
              InputLabelProps={{
                shrink: true,
              }}
              name="total"
              value={producto.total}
              onChange={handleChange}
            />

            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={guardarProducto}
            >
              AGREGAR
            </Button>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AddShopping;
