import {
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import useStylesMain from "../../styles/useStylesMain";
import makeStyles from "./styles";
import { saveMerge } from "../../state/actions/ProductActions";

const AddMerge = ({ setOpenMerge, idProduct, refreshList }) => {
  const [fechaSeleccionada, setFechaSeleccionada] = useState(new Date());
  const [unit, setUnit] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [detail, setDetail] = useState("");
  const [error, setError] = useState({});

  const [producto, setProducto] = useState({
    dateMerge: new Date(),
    quantity: 0,
    priceUnit: 0,
    priceTotal: 0,
    detail: "",
    productId: idProduct,
    type: "Less"
  });

  const validateFields = () => {
    let errors = {};
    if (!quantity || quantity <= 0) errors.quantity = "Cantidad requerida";
    if (!unit || unit <= 0) errors.unit = "Precio unidad requerido";
    if (!detail.trim()) errors.detail = "Detalle requerido";
    setError(errors);
    return Object.keys(errors).length === 0;
  };

  const saveMergeData = async () => {
    if (!validateFields()) return;
    
    const updatedProduct = {
      ...producto,
      dateMerge: fechaSeleccionada,
      priceUnit: unit,
      quantity,
      detail
    };

    saveMerge(updatedProduct).then(() => {
      refreshList();
      setOpenMerge(false);
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProducto((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleChangeUnit = (e) => {
    const item = e.target.value;
    setUnit(item);
    handleCalculate(item, quantity);
  };

  const handleChangeQuantity = (e) => {
    const item = e.target.value;
    setQuantity(item);
    handleCalculate(unit, item);
  };

  const handleCalculate = (item, quantity) => {
    if (parseFloat(item) > 0 && parseFloat(quantity) > 0) {
      setProducto((prev) => ({ ...prev, priceTotal: parseFloat(quantity) * parseFloat(item) }));
    } else {
      setProducto((prev) => ({ ...prev, priceTotal: 0 }));
    }
  };

  const classes = makeStyles();
  const classesMain = useStylesMain();

  return (
    <Container className={classesMain.containermt}>
      <Grid container justify="center">
        <Grid item sm={6} xs={12}>
          <Typography variant="h5" className={classesMain.text_title}>
            AGREGAR MERMA O DESPERDICIOS
          </Typography>
          <form onSubmit={(e) => e.preventDefault()} className={classesMain.form}>
            <TextField
              label="Cantidad producto"
              variant="outlined"
              fullWidth
              className={classesMain.gridmb}
              InputLabelProps={{ shrink: true }}
              name="quantity"
              value={quantity}
              onChange={handleChangeQuantity}
              error={!!error.quantity}
              helperText={error.quantity}
            />
            <TextField
              label="Precio unidad"
              variant="outlined"
              fullWidth
              className={classesMain.gridmb}
              InputLabelProps={{ shrink: true }}
              name="unit"
              value={unit}
              onChange={handleChangeUnit}
              error={!!error.unit}
              helperText={error.unit}
            />
            <TextField
              label="Precio total"
              variant="outlined"
              fullWidth
              className={classesMain.gridmb}
              InputLabelProps={{ shrink: true }}
              name="priceTotal"
              value={producto.priceTotal}
              disabled
            />
            <TextField
              label="Detalle"
              variant="outlined"
              fullWidth
              className={classesMain.gridmb}
              InputLabelProps={{ shrink: true }}
              name="detail"
              value={detail}
              onChange={(e) => setDetail(e.target.value)}
              error={!!error.detail}
              helperText={error.detail}
            />
            <Button variant="contained" color="primary" fullWidth onClick={saveMergeData}>
              AGREGAR
            </Button>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AddMerge;
