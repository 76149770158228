import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Icon,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import React, { useEffect, useState } from "react";
import useStylesMain from "../../styles/useStylesMain";
import { getProductBuysConfirm } from "../../state/actions/ShoppingActions";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { deleteOrderBuy, getCreateBuyedExcel, getPercentagePdf, updateProvider } from "../../state/actions/ProductActions";
import { useStateValue } from "../../state/store";

const ShoppingsBuy = () => {
  const classesMain = useStylesMain();
  const [{ sesionUsuario }, dispatch] = useStateValue();
  const [open, setOpen] = useState(false);
  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTo, setDateTo] = useState(new Date());
  const [idProduct, setIdProduct] = useState(0);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [precioUnidad, setPrecioUnidad] = useState('');
  const [productToDelete, setProductToDelete] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const [requestProductos, setRequestProductos] = useState({
    pageIndex: 1,
    pageSize: 10,
    search: "",
  });

  const [paginadorProductos, setPaginadorProductos] = useState({
    count: 0,
    pageIndex: 0,
    pageSize: 0,
    pageCount: 0,
    data: [],
  });

  const handleChange = (event, value) => {
    setRequestProductos((anterior) => ({
      ...anterior,
      pageIndex: value,
    }));
  };

  useEffect(() => {
    const getListaCompras = async () => {
      const response = await getProductBuysConfirm(requestProductos);
      setPaginadorProductos(response.data);
    };

    getListaCompras();
  }, [requestProductos]);

  const getBuyedPdf = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const generateBuyed = async () => {
    const printer = {
      dateFrom: dateFrom,
      dateTo: dateTo,
    };
    const listExcel = async (dataItem) => {
      const res = await getCreateBuyedExcel(dataItem);
      const file = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    };
    listExcel(printer);

    setOpen(false);
  };

  const addSelection = (id, gro, productId) => {
    const groups = gro.split(',');
    const handlePdfForGroup = async (group) => {
      const printer = {
        group: group,
        id: productId,
        provider: id
      };

      const res = await getPercentagePdf(printer);
      const file = new Blob([res.data], {
        type: "application/pdf",
      });

      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    };

    groups.forEach(group => {
      handlePdfForGroup(group);
    });
  };

  const editaProducto = (id) => {
    setIdProduct(id);
    setOpenUpdate(true);
  };
  const handleCloseUpdate = () => {
    setOpenUpdate(false);
  };

  const handlePrecioChange = (event) => {
    setPrecioUnidad(event.target.value);
  };

  const handleSave = () => {
    const productoData = {
      pricePurchase: parseFloat(precioUnidad),
      providerId: idProduct
    };

    updateProvider(idProduct, productoData).then((response) => {
      if (response.status === 200) {
        dispatch({
          type: "OPEN_SNACKBAR",
          openMensaje: {
            open: true,
            mensaje: "Se actualizo correctamente",
          },
        });

        const getListaCompras = async () => {
          const response = await getProductBuysConfirm(requestProductos);
          setPaginadorProductos(response.data);
        };

        getListaCompras();
      }
      else {
        dispatch({
          type: "OPEN_SNACKBAR",
          openMensaje: {
            open: true,
            mensaje: "No se pudo actualizar",
          },
        });
      }

    });
    handleCloseUpdate();
  };

  const handleDeleteClick = (id) => {
    setProductToDelete(id);
    setOpenConfirmDialog(true);
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
    setProductToDelete(null);
  };

  const handleConfirmDelete = () => {
    deleteSell(productToDelete);
    setOpenConfirmDialog(false);
    setProductToDelete(null);
  };

  const deleteSell = (id) => {
    deleteOrderBuy(id).then((response) => {
      const getListaCompras = async () => {
        const response = await getProductBuysConfirm(requestProductos);
        setPaginadorProductos(response.data);
      };

      getListaCompras();
    });
  };

  return (
    <Container className={classesMain.containermt}>
      <Grid container>
        <Grid item lg={6} sm={6} xs={12}>
          <Typography
            component={"span"}
            variant="h4"
            className={classesMain.text_title}
          >
            COMPRAS RECIBIDAS
          </Typography>
        </Grid>

        <Grid item lg={6} sm={6} xs={12}>

          <Button variant="contained" color="primary" onClick={getBuyedPdf}>
            GENERAR INFORME
          </Button>
        </Grid>
      </Grid>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>PRODUCTO</TableCell>
              <TableCell>FECHA CONFIRMADA</TableCell>
              <TableCell>CANTIDAD</TableCell>
              <TableCell>PRECIO UNIDAD</TableCell>
              <TableCell>PRECIO VENTA</TableCell>
              <TableCell>TOTAL</TableCell>
              <TableCell>FLETE</TableCell>
              <TableCell>GASTOS</TableCell>
              <TableCell>TOTAL GASTOS </TableCell>
              <TableCell>GRUPO</TableCell>
              <TableCell>CANTIDA DISPONIBLE</TableCell>
              <TableCell>DETALLES </TableCell>
              <TableCell>ACCIONES</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginadorProductos.data.map((producto) => (
              <TableRow key={producto.id}>
                <TableCell>{producto.product}</TableCell>
                <TableCell>
                  {new Date(producto.date).toLocaleString()}
                </TableCell>
                <TableCell>{producto.isKg ? producto.quantity + " Kg" : producto.quantity}</TableCell>
                <TableCell>
                  {producto.priceUnit}
                  <IconButton
                    color="secondary"
                    onClick={() => editaProducto(producto.id)}
                  >
                    <Icon>edit</Icon>
                  </IconButton>

                </TableCell>
                <TableCell>{producto.priceSale}</TableCell>
                <TableCell>{producto.totalCost}</TableCell>
                <TableCell>{producto.freight}</TableCell>
                <TableCell>{producto.another}</TableCell>
                <TableCell>{producto.total + " Bs"}</TableCell>
                <TableCell>{producto.group}</TableCell>
                <TableCell>{producto.received > 0 ? producto.received : ""}</TableCell>
                <TableCell>{producto.details}</TableCell>
                <TableCell>

                  {producto.isKg && <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      addSelection(
                        producto.id,
                        producto.group,
                        producto.productId
                      )
                    }
                  >
                    <Icon>print</Icon>
                  </Button>
                  }
                  {sesionUsuario.usuario.admin && <Button variant="contained" color="secondary" onClick={() => handleDeleteClick(producto.id)}>
                    Eliminar
                  </Button>}

                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        count={paginadorProductos.pageCount}
        page={paginadorProductos.pageIndex}
        onChange={handleChange}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">GENERAR REPORTE</DialogTitle>
        <DialogContent>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <KeyboardDatePicker
              value={dateFrom}
              onChange={setDateFrom}
              margin="normal"
              id="fecha-publicacion-id"
              label="Seleccione inicio"
              format="dd/MM/yyyy"
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <KeyboardDatePicker
              value={dateTo}
              onChange={setDateTo}
              margin="normal"
              id="fecha-publicacion-id"
              label="Seleccione fin"
              format="dd/MM/yyyy"
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={generateBuyed} color="primary" variant="contained">
            GENERAR
          </Button>
          <Button
            onClick={handleClose}
            color="primary"
            autoFocus
            variant="contained"
          >
            CANCELAR
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openUpdate}
        onClose={handleCloseUpdate}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Actualizar Precio compra</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="precioUnidad"
            label="Precio Unidad"
            type="number"
            value={precioUnidad}
            onChange={handlePrecioChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseUpdate}
            color="secondary"
            variant="contained"
            fullWidth
          >
            Cancelar
          </Button>
          <Button
            onClick={handleSave}
            color="primary"
            variant="contained"
            fullWidth
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openConfirmDialog}
        onClose={handleCancelDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirmar Eliminación</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Está seguro de que desea eliminar la compra?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleConfirmDelete} color="primary" autoFocus>
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ShoppingsBuy;
