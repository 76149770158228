import {
  Button,
  Container,
  DialogContentText,
  Icon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import useStylesMain from "../../styles/useStylesMain";
import { Pagination } from "@material-ui/lab";
import {
  deleteUserList,
  getUsers,
} from "../../state/actions/UserActions";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import EditUser from "./EditUser";
import RegisterUser from "./RegisterUser";
import { useStateValue } from "../../state/store";

const Users = () => {
  const classesMain = useStylesMain();
  const [{ sesionUsuario }, dispatch] = useStateValue();

  const [open, setOpen] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [idUser, setIdUser] = useState("");

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const [requestUsuarios, setRequestUsuarios] = useState({
    pageIndex: 1,
    pageSize: 20,
    search: "",
  });

  const [paginadorUsuarios, setPaginadorUsuarios] = useState({
    count: 0,
    pageIndex: 0,
    pageSize: 0,
    PageCount: 0,
    data: [],
  });

  const handleChange = (event, value) => {
    setRequestUsuarios((anterior) => ({
      ...anterior,
      pageIndex: value,
    }));
  };

  useEffect(() => {
    const getListaUsuarios = async () => {
      const response = await getUsers(requestUsuarios);
      setPaginadorUsuarios(response.data);
    };

    getListaUsuarios();
  }, [requestUsuarios]);

  const editaUsuario = (id) => {
    setOpen(true);
    setIdUser(id);
  };

  const removerUsuario = (id) => {
    setOpenDelete(true);
    setIdUser(id);
  };

  const refreshList = () => {
    const getListaUsuarios = async () => {
      const response = await getUsers(requestUsuarios);
      setPaginadorUsuarios(response.data);
    };

    getListaUsuarios();
  };

  const agregarUsuario = () => {
    setOpenAdd(true);
  };

  const deleteUser = () => {
    deleteUserList(idUser).then((response) => {
      const getListaUsuarios = async () => {
        const responsed = await getUsers(requestUsuarios);
        setPaginadorUsuarios(responsed.data);
      };

      getListaUsuarios();
    });

    setOpenDelete(false);
  };

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setRequestUsuarios((prev) => ({
      ...prev,
      search: value,
      pageIndex: 1,
    }));
  };

  return (
    <Container className={classesMain.containermt}>
      {/* inio */}
      <Typography variant="h4" className={classesMain.text_title}>
        USUARIOS
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="right" lg={6} sm={6} xs={12}>
                <TextField
                  label="Buscar Usuario"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  onChange={handleSearchChange}
                />
              </TableCell>
              <TableCell lg={6} sm={6} xs={12}>
                <Button
                  variant="contained"
                  color="secondary"

                  className={classesMain.buttonAgregar}
                  onClick={agregarUsuario}
                >
                  <Icon>add</Icon>
                  NUEVO USUARIO
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>

        </Table>
      </TableContainer>
      {/* fin */}

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nombre</TableCell>
              <TableCell>Rol</TableCell>
              <TableCell>CODIGO</TableCell>
              <TableCell>GRUPO</TableCell>
              <TableCell>CELULAR</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginadorUsuarios.data.map((usuario) => (
              <TableRow key={usuario.id}>

                <TableCell>{usuario.lastName + " " + usuario.name}</TableCell>
                <TableCell>{usuario.rolName}</TableCell>
                <TableCell>{usuario.code}</TableCell>
                <TableCell>{usuario.group > 0 ? usuario.group : ""}</TableCell>
                <TableCell>{usuario.phone}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => editaUsuario(usuario.id)}
                  >
                    <Icon>edit</Icon>
                  </Button>
                  {sesionUsuario.usuario.admin && <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => removerUsuario(usuario.id)}
                  >
                    <Icon>delete</Icon>
                  </Button>
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        count={paginadorUsuarios.pageCount}
        page={paginadorUsuarios.pageIndex}
        onChange={handleChange}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <EditUser
          idUser={idUser}
          setOpen={setOpen}
          refreshList={refreshList}
        ></EditUser>

        <DialogActions>
          <Button onClick={handleClose} color="secondary" fullWidth variant="contained">
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openAdd}
        onClose={handleCloseAdd}
        aria-labelledby="form-dialog-title"
      >
        <RegisterUser
          setOpenAdd={setOpenAdd}
          refreshList={refreshList}
        ></RegisterUser>

        <DialogActions>
          <Button onClick={handleCloseAdd} color="secondary" fullWidth variant="contained">
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Eliminar usuario</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Esta seguro de eliminar el usuario?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={deleteUser} color="primary" fullWidth variant="contained">
            Eliminar
          </Button>
          <Button onClick={handleCloseDelete} color="secondary" fullWidth autoFocus variant="contained">
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Users;
