import {
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import useStylesMain from "../../styles/useStylesMain";
import makeStyles from "./styles";
import { saveIncrease } from "../../state/actions/ProductActions";

const AddIncrease = ({ setOpenIncrease, idProduct, refreshList }) => {
  const [fechaSeleccionada, setFechaSeleccionada] = useState(new Date());
  const [unit, setUnit] = useState("");
  const [quantity, setQuantity] = useState("");
  const [priceTotal, setPriceTotal] = useState(0);
  const [detail, setDetail] = useState("");
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let newErrors = {};

    if (!quantity || parseFloat(quantity) <= 0) newErrors.quantity = "Cantidad requerida";
    if (!unit || parseFloat(unit) <= 0) newErrors.unit = "Precio unidad requerido";
    if (!detail.trim()) newErrors.detail = "Detalle requerido";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const saveMergeData = async () => {
    if (!validateForm()) return;

    const producto = {
      dateMerge: fechaSeleccionada,
      priceUnit: parseFloat(unit),
      quantity: parseFloat(quantity),
      priceTotal: parseFloat(priceTotal),
      detail,
      productId: idProduct,
      type: "Add",
    };

    saveIncrease(producto).then(() => {
      refreshList();
    });

    setOpenIncrease(false);
  };

  const handleChangeQuantity = (e) => {
    const value = e.target.value;
    setQuantity(value);
    setErrors((prev) => ({ ...prev, quantity: "" }));
    updatePriceTotal(value, unit);
  };

  const handleChangeUnit = (e) => {
    const value = e.target.value;
    setUnit(value);
    setErrors((prev) => ({ ...prev, unit: "" }));
    updatePriceTotal(quantity, value);
  };

  const updatePriceTotal = (quantity, unit) => {
    const total = parseFloat(quantity) > 0 && parseFloat(unit) > 0
      ? parseFloat(quantity) * parseFloat(unit)
      : 0;
    setPriceTotal(total.toFixed(2)); // Redondear a 2 decimales
  };

  const handleChangeDetail = (e) => {
    setDetail(e.target.value);
    setErrors((prev) => ({ ...prev, detail: "" }));
  };

  const classes = makeStyles();
  const classesMain = useStylesMain();

  return (
    <Container className={classesMain.containermt}>
      <Grid container justify="center">
        <Grid item sm={6} xs={12}>
          <Typography variant="h5" className={classesMain.text_title}>
            AGREGAR INCREMENTO
          </Typography>
          <form onSubmit={(e) => e.preventDefault()} className={classesMain.form}>
            <TextField
              label="Cantidad producto"
              variant="outlined"
              fullWidth
              className={classesMain.gridmb}
              InputLabelProps={{ shrink: true }}
              name="quantity"
              value={quantity}
              onChange={handleChangeQuantity}
              error={!!errors.quantity}
              helperText={errors.quantity}
            />
            <TextField
              label="Precio unidad"
              variant="outlined"
              fullWidth
              className={classesMain.gridmb}
              InputLabelProps={{ shrink: true }}
              name="unit"
              value={unit}
              onChange={handleChangeUnit}
              error={!!errors.unit}
              helperText={errors.unit}
            />
            <TextField
              label="Precio total"
              variant="outlined"
              fullWidth
              className={classesMain.gridmb}
              InputLabelProps={{ shrink: true }}
              name="priceTotal"
              value={priceTotal}
              disabled
            />
            <TextField
              label="Detalle"
              variant="outlined"
              fullWidth
              multiline
              rows={2}
              className={classesMain.gridmb}
              InputLabelProps={{ shrink: true }}
              name="detail"
              value={detail}
              onChange={handleChangeDetail}
              error={!!errors.detail}
              helperText={errors.detail}
            />
            <Button variant="contained" color="primary" fullWidth onClick={saveMergeData}>
              AGREGAR
            </Button>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AddIncrease;
