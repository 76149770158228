import React from 'react';
import { Grid, Paper } from '@material-ui/core';
import useStylesMain from '../../styles/useStylesMain';
import Loan from './Loan';
import PayPil from './PayPil';


const AllCartera = () => {
  const classes = useStylesMain();


  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item >
          <Paper className={classes.card}>
            <Loan></Loan>
          </Paper>
        </Grid>
        <Grid item >
          <Paper className={classes.card}>
            <PayPil></PayPil>
          </Paper>
        </Grid>

      </Grid>
    </div>
  );
};

export default AllCartera;
