import React, { useState } from "react";
import {
  Button,
  TextField,
  Typography,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
} from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import * as XLSX from "xlsx";
import { registerLoan } from "../../state/actions/DairyAction";
import { useStateValue } from "../../state/store";
import { TablePagination } from "@material-ui/core";

const PayReal = () => {
  const [{ sesionUsuario }, dispatch] = useStateValue();
  const [fileData, setFileData] = useState([]);
  const [date, setDate] = useState("");
  const [detail, setDetail] = useState("");
  const [search, setSearch] = useState("");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet);

      const formattedData = jsonData.map((row) => ({
        codigo: row["CODIGO"].toString(),
        monto: row["MONTO"],
      }));

      setFileData(formattedData);
    };
    reader.readAsArrayBuffer(file);
  };

  const handleClear = () => {
    setFileData([]);
    setDate("");
    setDetail("");
    setSearch("");
  };

  const handleSubmit = async () => {
    if (!date || !detail || fileData.length === 0) {
      dispatch({
        type: "OPEN_SNACKBAR",
        openMensaje: {
          open: true,
          mensaje: "Por favor, complete todos los campos y cargue un archivo Excel válido.",
        },
      });
      return;
    }

    const payload = {
      quincena: date,
      detail: detail,
      datos: fileData,
    };

    try {
      const response = await registerLoan(payload);

      if (response && response.status === 200) {
        dispatch({
          type: "OPEN_SNACKBAR",
          openMensaje: {
            open: true,
            mensaje: "Datos enviados correctamente.",
          },
        });
      } else if (response && response.status === 409) {
        // Si el servidor devuelve un conflicto (409), significa que ya existen datos para la quincena
        dispatch({
          type: "OPEN_SNACKBAR",
          openMensaje: {
            open: true,
            mensaje: "Ya existen prestamos registrados para esta quincena. No se puede insertar nuevos datos.",
          },
        });
      } else {
        dispatch({
          type: "OPEN_SNACKBAR",
          openMensaje: {
            open: true,
            mensaje: "Hubo un problema al enviar los datos.",
          },
        });
      }
    } catch (error) {
      dispatch({
        type: "OPEN_SNACKBAR",
        openMensaje: {
          open: true,
          mensaje: "Error al enviar los datos. Por favor, inténtelo de nuevo.",
        },
      });
    }
  };


  return (
    <Container maxWidth="sm">
      <Typography variant="h5" gutterBottom>
        Selecciona la fecha a la quincena que deseas cargar los prestamos y luego carga el archivo Excel para enviar esos datos.
      </Typography>

      <Box mb={2}>
        <TextField
          label="Seleccionar Quincena"
          type="date"
          fullWidth
          margin="normal"
          InputLabelProps={{ shrink: true }}
          value={date}
          onChange={(e) => setDate(e.target.value)}
        />
      </Box>

      <Box mb={2}>
        <TextField
          label="Detalle"
          type="text"
          fullWidth
          margin="normal"
          value={detail}
          onChange={(e) => setDetail(e.target.value)}
        />
      </Box>

      <Box mb={2}>
        <input
          id="file-upload"
          type="file"
          accept=".xlsx, .xls"
          style={{ display: "none" }}
          onChange={handleFileUpload}
        />
        <label htmlFor="file-upload">
          <Button
            variant="contained"
            color="primary"
            component="span"
            startIcon={<CloudUploadIcon />}
            style={{ marginBottom: '16px' }}
          >
            Subir archivo Excel
          </Button>
        </label>
      </Box>

      <Box mb={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >
          Enviar Datos
        </Button>
      </Box>
      <Box mb={2}>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleClear}
          style={{ marginTop: '8px' }}
        >
          Limpiar
        </Button>
      </Box>

      <Box mb={2}>
        <TextField
          label="Buscar por Código"
          fullWidth
          margin="normal"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Código</TableCell>
              <TableCell>Monto</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fileData
              .filter((row) => row.codigo.toString().includes(search))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.codigo}</TableCell>
                  <TableCell>{row.monto}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={fileData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Container>
  );
};

export default PayReal;
