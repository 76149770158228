import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Divider,
  Grid,
  Switch,
  TextareaAutosize,
  TextField,
  Typography,
} from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { getPilByCode } from "../../state/actions/PilActions";
import { getProducto, registrarCompra, saleProduct } from "../../state/actions/ProductActions";
import useStylesMain from "../../styles/useStylesMain";
import makeStyles from "./styles";

const DetailsProductUserSoy = ({ idProduct, setOpenSoy }) => {
  const [cantidad, setCantidad] = useState(0);
  const [code, setCode] = useState("");
  const [soya, setSoya] = useState(0);
  const [available, setAvailable] = useState(0);
  const [totalSoya, setTotalSoya] = useState(0);
  const [quota, setQuota] = useState(0);
  const [price, setPrice] = useState(0.0);
  const [exceed, setExceed] = useState(0.0);
  const [name, setName] = useState("");
  const [loadingEx, setLoadingEx] = useState(false);
  const [dateFrom, setDateFrom] = useState(new Date());

  const [productoSeleccionado, setProductoSeleccionado] = useState({
    id: 0,
    name: "",
    description: "",
    stock: 0,
    stockMax: 0,
    stockMin: 0,
    brandId: 0,
    priceSale: 0.0,
    pricePurchase: 0.0,
    imageGeneral: null,
    imagenTemporal: "",
    image: "",
    brandName: "",
    isInKg: false,
    codeCategory: "",
  });

  const [pil, setPil] = useState({
    id: 0,
    name: "",
    code: "",
    milk: 0,
    soy: 0,
  });

  useEffect(() => {
    const getProductoAsync = async () => {
      const response = await getProducto(idProduct);
      setProductoSeleccionado(response.data);

      let fotoPerfil = response.data.imageGeneral;
      const nuevoFile =
        "data:image/" + fotoPerfil.extension + ";base64," + fotoPerfil.data;
      setProductoSeleccionado((anterior) => ({
        ...anterior,
        imagenTemporal: nuevoFile,
      }));
    };
    getProductoAsync();
  }, [idProduct]);

  const handleChange = (e) => {
    const { value } = e.target;
    setCode(value);
  };

  const setCantidadEvent = (e) => {
    const { value } = e.target;
    setCantidad(value);
  };

  const changeData = (kg, stock) => {
    let usd = kg ? " (Kg) " : " (uds)";
    return stock + usd;
  };

  const handleKey = (e) => {
    if (e.key === "Enter") {
      const getPilObject = async () => {
        const response = await getPilByCode(code, idProduct);
        setPil(response.data);
        setQuota(response.data.soy);
        setName(response.data.name);
        setAvailable(response.data.soy);
      };
      getPilObject();
    }
  };

  const searchAssociate = async () => {
    const getPilObject = async () => {
      const response = await getPilByCode(code, idProduct);
      setPil(response.data);
      setQuota(response.data.soy);
      setName(response.data.name);
      setAvailable(response.data.soy);
    };
    getPilObject();
  }

  const saveAll = async () => {
    if (loadingEx) {
      const item = {
        id: idProduct,
        cantidad: 0,
        code: code,
        price: 0,
        priceExceed: totalSoya * price,
        cantidadExceed: totalSoya,
        dateSale: dateFrom,
        unitPrice:price,
        detailBuy:soya
      };

      registrarCompra(item).then((response) => {
        setOpenSoy(false);
      });
    } else {
      const item = {
        id: idProduct,
        cantidad: totalSoya,
        code: code,
        price: totalSoya * productoSeleccionado.priceSale,
        priceExceed: 0,
        cantidadExceed: 0,
        dateSale: dateFrom,
        unitPrice: productoSeleccionado.priceSale,
        detailBuy:soya
      };

      saleProduct(item).then((response) => {
        setOpenSoy(false);
      });
    }
  }

  const add = async () => {
    let sum = parseFloat(totalSoya) + parseFloat(cantidad);
    setTotalSoya(sum);
    setAvailable(quota - sum);
    if (parseFloat(soya) === 0) {
      setSoya(cantidad);
    } else {
      setSoya(soya + " + " + cantidad);
    }
    setCantidad(0);
  };

  const changeSwitch = async () => {
    setLoadingEx(!loadingEx);
    setAvailable(0);
    setSoya(0);
    setCantidad(0);
    setExceed(0);
    setPrice(0);
    setTotalSoya(0);
  };

  const addExceed = async () => {
    let sum = parseFloat(totalSoya) + parseFloat(exceed);
    setTotalSoya(sum);
    if (parseFloat(soya) === 0) {
      setSoya(exceed);
    } else {
      setSoya(soya + " + " + exceed);
    }
    setExceed(0);
  };


  const classesMain = useStylesMain();
  const classes = makeStyles();

  return (
    <Container>
      <Typography variant="h4" className={classesMain.text_title}>
        {productoSeleccionado.name}
      </Typography>

      <Grid container spacing={2}>
        <Grid item md={6}>
          <Typography className={classes.text_detalleS}>
            PRECIO: {productoSeleccionado.priceSale} Bs
          </Typography>
          <Typography className={classes.text_detalleS}>
            CANTIDAD:{" "}
            {changeData(
              productoSeleccionado.isInKg,
              productoSeleccionado.stock
            )}
          </Typography>
          <Typography className={classes.text_detalleS}>
            MARCA: {productoSeleccionado.brandName}
          </Typography>
          <Typography className={classes.text_detalleS}>
            DETALLES: {productoSeleccionado.description}
          </Typography>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />

      <Grid item lg={12} md={12} xs={12}>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <Typography variant="h6">Cantidad que está llevando:</Typography>
            <TextareaAutosize
              disabled
              className={classes.mediaTotal}
              maxRows={10}
              aria-label="maximum height"
              placeholder="Maximum 4 rows"
              defaultValue={soya}
            />
            <Typography variant="h6">Total a llevar: {totalSoya}</Typography>
            <Typography variant="h6" color="error">Disponible de cupo: {available}</Typography>
            <Button
              variant="contained"
              color="secondary"
              size="large"

              className={classes.button}
              onClick={saveAll}
            >
              Guardar todo
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Divider className={classes.dividerS} />

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            label="Codigo Socio"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            name="code"
            value={code}
            className={classesMain.gridmb}
            onChange={handleChange}
            onKeyDown={handleKey}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            fullWidth
            className={classes.button}
            onClick={searchAssociate}
          >
            Buscar socio
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            label="Socio"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            className={classesMain.gridmb}
            fullWidth
            name="name"
            value={name}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Cupo Socio"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            className={classesMain.gridmb}
            name="quota"
            value={quota}
            disabled
          />
        </Grid>
        {/* <Grid item xs={12} md={4}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <KeyboardDatePicker
              value={dateFrom}
              onChange={setDateFrom}
              margin="normal"
              id="fecha-publicacion-id"
              label="Fecha de venta"
              format="dd/MM/yyyy"
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid> */}
      </Grid>

      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <Typography>Seleccione si es para excedido:</Typography>
        </Grid>
        <Grid item>
          <Switch
            checked={loadingEx}
            onChange={changeSwitch}
            color="primary"
          />
        </Grid>
      </Grid>

      {!loadingEx && (
        <div>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <TextField
                label="Cantidad a llevar"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                className={classesMain.gridmb}
                value={cantidad}
                onChange={setCantidadEvent}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                onClick={add}
                className={classes.button}
              >
                Sumar
              </Button>
            </Grid>
          </Grid>
          <Divider className={classes.dividerS} />
        </div>
      )}

      {loadingEx && (
        <div>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <TextField
                label="Cantidad excedido"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                className={classesMain.gridmb}
                name="exceed"
                value={exceed}
                onChange={(event) => setExceed(event.target.value)}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                label="Precio excedido"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                className={classesMain.gridmb}
                name="price"
                value={price}
                onChange={(event) => setPrice(event.target.value)}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                fullWidth
                className={classes.button}
                onClick={addExceed}
              >
                Agregar
              </Button>
            </Grid>
          </Grid>
          <Divider className={classes.dividerS} />
        </div>
      )}
    </Container>
  );
};

export default DetailsProductUserSoy;
