import AxiosRequest from "../axios/AxiosRequest";
import axios from 'axios';

const instancia = axios.create();
instancia.CancelToken = axios.CancelToken;
instancia.isCancel = axios.isCancel;


export const registerPay = async (payload) => {

    return new Promise((resolve, eject) => {
        AxiosRequest.post("/api/dairy/pay", payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                resolve(error.response);
            });
    });

}

export const getPays = fecha => {
    return new Promise((resolve, eject) => {
        AxiosRequest.getOnly(`/api/dairy/pays/${fecha}`)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                resolve(error.response);
            });
    });
}

export const deletePays = async (fecha) => {

    return new Promise((resolve, eject) => {

        AxiosRequest.delete(`/api/dairy/pays/${fecha}`)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                resolve(error.response);
            })
    });
}

export const registerLoan = async (payload) => {

    return new Promise((resolve, eject) => {
        AxiosRequest.post("/api/dairy/loan/update", payload)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                resolve(error.response);
            });
    });

}

export const getLoans = fecha => {
    return new Promise((resolve, eject) => {
        AxiosRequest.getOnly(`/api/dairy/loans/${fecha}`)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                resolve(error.response);
            });
    });
}

export const updateLoanStatus = (itemPay) => {
    return new Promise( (resolve, eject) => {
        AxiosRequest.getByJasonPdf("/api/dairy/loan/update", itemPay).then( response =>{
            resolve(response);
        });
    })    
}

export const deleteLoans = async (fecha) => {

    return new Promise((resolve, eject) => {

        AxiosRequest.delete(`/api/dairy/loans/${fecha}`)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                resolve(error.response);
            })
    });
}

export const getPaysExcel = (item) => {
    return new Promise( (resolve, eject) => {
        AxiosRequest.getByJasonPdf("/api/dairy/paysExcel", item).then( response =>{
            resolve(response);
        });
    })    
}

export const getLoansExcel = (item) => {
    return new Promise( (resolve, eject) => {
        AxiosRequest.getByJasonPdf("/api/dairy/loansExcel", item).then( response =>{
            resolve(response);
        });
    })    
}