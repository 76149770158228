import { BrowserRouter as Router } from "react-router-dom";
import React, { useEffect, useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Hidden from "@material-ui/core/Hidden";
import Navigator from "./components/views/Menu/Navigator";
import makeStyles from "./components/views/Menu/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import Footer from "./components/layout/Footer";
import themeMenu from "./components/views/Menu/themeMenu";
import { Switch, Route } from "react-router-dom";
import TabUser from "./components/views/User/TabUser";
import TabProduct from "./components/views/Product/TabProduct";
import TabBuys from "./components/views/Buy/TabBuys";
import Login from "./components/views/Login/Login";
import TabShopping from "./components/views/Provider/TabShopping";
import TabPil from "./components/views/Pil/TabPil";
import TabReport from "./components/views/Report/TabReport";
import { useStateValue } from "./components/state/store";
import { Snackbar } from "@material-ui/core";
import { getUser } from "./components/state/actions/UserActions";
import TabTractor from "./components/views/Tractor/TabTractor";
import TabLoan from "./components/views/Loan/TabLoan";
import TabLoanReal from "./components/views/LoanReal/TabLoanReal";

const drawerWidth = 256;

const MainApp = () => {
  const classes = makeStyles();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [{ openSnackbar, sesionUsuario }, dispatch] = useStateValue();

  const [iniciaApp, setIniciaApp] = useState(false);

  useEffect(() => {
    if (!iniciaApp) {
      getUser(dispatch)
        .then((response) => {
          setIniciaApp(true);
        })
        .catch((error) => {
          setIniciaApp(true);
        });
    }
  }, [iniciaApp]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openSnackbar ? openSnackbar.open : false}
        autoHideDuration={3000}
        ContentProps={{ "aria-describedby": "message-id" }}
        message={
          <span id="message-id">
            {openSnackbar ? openSnackbar.mensaje : ""}
          </span>
        }
        onClose={() =>
          dispatch({
            type: "OPEN_SNACKBAR",
            openMensaje: {
              open: false,
              mensaje: "",
            },
          })
        }
      ></Snackbar>
      <Router>
        <ThemeProvider theme={themeMenu}>
        
            <div>
              <Switch>
                <Route path="/" exact>
                  <Login></Login>
                </Route>
              </Switch>
            </div>
          

          {sesionUsuario && sesionUsuario.autenticado == true && (
            <div className={classes.root}>
              <CssBaseline />
              <nav className={classes.drawer}>
                <Hidden smUp>
                  <Navigator
                    PaperProps={{ style: { width: drawerWidth } }}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                  />
                </Hidden>
                <Hidden xsDown>
                  <Navigator PaperProps={{ style: { width: drawerWidth } }} />
                </Hidden>
              </nav>
              <div className={classes.app}>
                <main className={classes.main}>
                  <Switch>
                    <Route path="/users" exact>
                      <TabUser onDrawerToggle={handleDrawerToggle}></TabUser>
                    </Route>

                    <Route exact path="/products">
                      <TabProduct
                        onDrawerToggle={handleDrawerToggle}
                      ></TabProduct>
                    </Route>

                    <Route exact path="/shopping">
                      <TabShopping
                        onDrawerToggle={handleDrawerToggle}
                      ></TabShopping>
                    </Route>

                    <Route exact path="/buys">
                      <TabBuys onDrawerToggle={handleDrawerToggle}></TabBuys>
                    </Route>   
                    <Route exact path="/loan">
                      <TabLoan onDrawerToggle={handleDrawerToggle}></TabLoan>
                    </Route> 
                    <Route exact path="/loanReal">
                      <TabLoanReal onDrawerToggle={handleDrawerToggle}></TabLoanReal>
                    </Route>                  

                    <Route exact path="/pil">
                      <TabPil onDrawerToggle={handleDrawerToggle}></TabPil>
                    </Route>
                    <Route exact path="/report">
                      <TabReport onDrawerToggle={handleDrawerToggle}></TabReport>
                    </Route>
                    <Route exact path="/tractor">
                      <TabTractor onDrawerToggle={handleDrawerToggle}></TabTractor>
                    </Route>
                  </Switch>
                </main>
                <footer className={classes.footer}>
                  <Footer></Footer>
                </footer>
              </div>
            </div>
          )}
        </ThemeProvider>
      </Router>
    </>
  );
};

export default MainApp;
