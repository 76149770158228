import {
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import useStylesMain from "../../styles/useStylesMain";
import {
  getAllContribution,
  registerContributionAssociate,
} from "../../state/actions/PilActions";
import { Autocomplete } from "@material-ui/lab";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

const AddAssocite = ({ setOpen, refreshList }) => {
  const [fechaSeleccionada, setFechaSeleccionada] = useState(new Date());
  const [pil, setPil] = useState({
    contribution: "",
    date: "",
  });

  const [inputProvider, setInputProvider] = useState(null);

  const [providers, setProviders] = useState({
    data: [],
  });

  useEffect(() => {
    const getListProviders = async () => {
      const response = await getAllContribution();
      setProviders(response.data);
    };

    getListProviders();
  }, []);

  const saveMilk = async () => {
    pil.contribution = inputProvider.id;  
    // Convierte la fecha local a UTC
    const fechaUTC = new Date(
      fechaSeleccionada.getTime() - fechaSeleccionada.getTimezoneOffset() * 60000
    ).toISOString();
  
    pil.date = fechaUTC;
    registerContributionAssociate(pil).then((response) => {
      refreshList();
    });
  
    setOpen(false);
  };
  const classesMain = useStylesMain();

  return (
    <Container className={classesMain.containermt}>
      <Grid container justify="center">
        <Grid item sm={10} xs={12}>
          <Typography variant="h5" className={classesMain.text_title}>
            AGREGAR APORTE
          </Typography>
          <form
            onSubmit={(e) => e.preventDefault()}
            className={classesMain.form}
          >
            <Autocomplete
              options={providers}
              getOptionLabel={(option) => option.name}
              id="controlled-demo"
              value={inputProvider}
              onChange={(event, newValue) => {
                setInputProvider(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Aportes"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
            <br></br>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <KeyboardDatePicker
                value={fechaSeleccionada}
                onChange={setFechaSeleccionada}
                margin="normal"
                id="fecha-publicacion-id"
                label="Seleccione Fecha"
                format="dd/MM/yyyy"
                fullWidth
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>

            <Button variant="contained" fullWidth color="primary" onClick={saveMilk}>
              AGREGAR
            </Button>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AddAssocite;
