import React, { useState, useEffect } from 'react';
import {
  Button, TextField, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, TablePagination, Paper, Typography, Checkbox
} from '@material-ui/core';
import { useStateValue } from '../../state/store';
import { deleteLoans, getLoans, updateLoanStatus } from '../../state/actions/DairyAction';

const PaysReal = () => {
  const [{ sesionUsuario }, dispatch] = useStateValue();
  const [fecha, setFecha] = useState('');
  const [fileData, setFileData] = useState([]);
  const [selectedRows, setSelectedRows] = useState({});
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [noDataMessage, setNoDataMessage] = useState('');

  useEffect(() => {
    if (fecha) {
      fetchData(fecha);
    }
  }, [fecha]);

  const fetchData = async (selectedDate) => {
    const response = await getLoans(selectedDate);
    if (response.data.length === 0) {
      setNoDataMessage('No hay préstamos para esta quincena.');
      setSearch('');
    } else {
      setNoDataMessage('');
    }

    // Crear estado inicial basado en isPay
    const initialSelectedRows = response.data.reduce((acc, row) => {
      acc[row.id] = row.isPay;
      return acc;
    }, {});

    setFileData(response.data);
    setSelectedRows(initialSelectedRows);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDelete = () => {
    if (!fecha) {
      dispatch({
        type: 'OPEN_SNACKBAR',
        openMensaje: { open: true, mensaje: 'Por favor, selecciona una quincena.' },
      });
      return;
    }
    deleteLoans(fecha);
    setFileData([]);
    dispatch({
      type: 'OPEN_SNACKBAR',
      openMensaje: { open: true, mensaje: 'Datos eliminados correctamente.' },
    });
  };

  const handleCheckboxChange = async (codigo) => {
    const newSelectedRows = { ...selectedRows, [codigo]: !selectedRows[codigo] };
    setSelectedRows(newSelectedRows);

    const payload = {
      code: codigo,
      row: newSelectedRows[codigo]
    };

    try {
      const res = await updateLoanStatus(payload);
      if (payload.row) {
        const file = new Blob([res.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }

      dispatch({
        type: 'OPEN_SNACKBAR',
        openMensaje: { open: true, mensaje: 'Estado actualizado correctamente.' },
      });
    } catch (error) {
      dispatch({
        type: 'OPEN_SNACKBAR',
        openMensaje: { open: true, mensaje: 'Error al actualizar estado.' },
      });
    }
  };

  return (
    <div>
      <Typography variant="h5" gutterBottom>
        Selecciona la fecha de la quincena para cargar los préstamos:
      </Typography>

      <TextField
        label="Seleccionar Quincena"
        type="date"
        fullWidth
        margin="normal"
        InputLabelProps={{ shrink: true }}
        value={fecha}
        onChange={(e) => setFecha(e.target.value)}
      />

      <TextField
        label="Buscar por Código"
        fullWidth
        margin="normal"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        style={{ marginTop: '16px' }}
      />

      {noDataMessage && (
        <Typography variant="body1" color="textSecondary" style={{ marginTop: '16px' }}>
          {noDataMessage}
        </Typography>
      )}

      <TableContainer component={Paper} style={{ marginTop: '16px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Pagado</TableCell>
              <TableCell>Código</TableCell>
              <TableCell>Monto</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fileData
              .filter((row) => row.codigo.toString().includes(search))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow key={row.id}>
                  <TableCell>
                    <Checkbox
                      checked={!!selectedRows[row.id]} // Asegura que sea booleano
                      onChange={() => handleCheckboxChange(row.id)}
                    />
                  </TableCell>
                  <TableCell>{row.codigo}</TableCell>
                  <TableCell>{row.monto}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={fileData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>

      <Button
        variant="contained"
        color="secondary"
        onClick={handleDelete}
        style={{ marginTop: '16px' }}
      >
        Eliminar todos los datos de esta quincena
      </Button>
    </div>
  );
};

export default PaysReal;
