import React, { useState, useEffect } from 'react';
import { Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Paper, Typography } from '@material-ui/core';
import { useStateValue } from '../../state/store';
import { deletePays, getPays } from '../../state/actions/DairyAction';

const Pays = () => {
  const [{ sesionUsuario }, dispatch] = useStateValue();
  const [fecha, setFecha] = useState('');
  const [fileData, setFileData] = useState([]);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [noDataMessage, setNoDataMessage] = useState('');

  useEffect(() => {
    if (fecha) {
      fetchData(fecha);
    }
  }, [fecha]);

  const fetchData = async (selectedDate) => {   
    const response = await getPays(selectedDate);
    if (response.data.length === 0) {
      setNoDataMessage('No hay pagos para esta quincena.');
      setSearch("");
    } else {
      setNoDataMessage('');
    }
    setFileData(response.data); 
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDelete = () => {
    if (!fecha) {
      dispatch({
        type: 'OPEN_SNACKBAR',
        openMensaje: {
          open: true,
          mensaje: 'Por favor, selecciona una quincena.',
        },
      });
      return;
    }

    deletePays(fecha);
    setFileData([]); 
    dispatch({
      type: 'OPEN_SNACKBAR',
      openMensaje: {
        open: true,
        mensaje: 'Datos eliminados correctamente.',
      },
    });
  };

  return (
    <div>
      <Typography variant="h5" gutterBottom>
        Selecciona la fecha de la quincena para cargar los pagos:
      </Typography>

      <TextField
        label="Seleccionar Quincena"
        type="date"
        fullWidth
        margin="normal"
        InputLabelProps={{ shrink: true }}
        value={fecha}
        onChange={(e) => setFecha(e.target.value)}
      />

      {/* Campo de búsqueda */}
      <TextField
        label="Buscar por Código"
        fullWidth
        margin="normal"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        style={{ marginTop: '16px' }}
      />

      {/* Mensaje si no hay datos */}
      {noDataMessage && (
        <Typography variant="body1" color="textSecondary" style={{ marginTop: '16px' }}>
          {noDataMessage}
        </Typography>
      )}

      <TableContainer component={Paper} style={{ marginTop: '16px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Código</TableCell>
              <TableCell>Monto</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fileData
              .filter((row) => row.codigo.toString().includes(search)) // Filtrado por código
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.codigo}</TableCell>
                  <TableCell>{row.monto}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={fileData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>

      <Button
        variant="contained"
        color="secondary"
        onClick={handleDelete}
        style={{ marginTop: '16px' }}
      >
        Eliminar todos los datos de esta quincena
      </Button>
    </div>
  );
};

export default Pays;
