import {
  Button,
  Checkbox,
  Container,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Paper,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import useStylesMain from "../../styles/useStylesMain";
import makeStyles from "./styles";
import {
  getPayPdf,
  getDiscountByAssociate,
  selectPay,
  deleteOrderInvoce,
  getPaymentInvoce,
  savePaymentExtra,
  getCuotasPdf,
} from "../../state/actions/ProductActions";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { useStateValue } from "../../state/store";

const Discount = ({ itemData, setOpenDiscount, addSelectionPay }) => {
  const [{ sesionUsuario}, dispatch] = useStateValue();
  const [group, setGroup] = useState(0);
  const [priceCuota, setPriceCuota] = useState(0);
  const [change, setChange] = useState(0);
  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateCuota, setDateCuota] = useState(new Date());
  const classes = makeStyles();
  const classesMain = useStylesMain();

  const [selectedProducts, setSelectedProducts] = useState({});
  const [totalPrice, setTotalPrice] = useState(0);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openCuotaDialog, setOpenCuotaDialog] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);
  const [productSale, setProductSale] = useState(null);

  const handleChangeGroup = (e) => {
    setGroup(e.target.value);
    if (totalPrice > 0 && e.target.value > 0) {
      setChange(e.target.value - totalPrice);
    }
  };

  const handleChangeCupo = (e) => {
    setPriceCuota(e.target.value);
    
  };

  const deleteSell = (id) => {
    deleteOrderInvoce(id).then((response) => {
      const getListaProductos = async () => {
        const response = await getDiscountByAssociate(itemData.id);
        setProductsData(response);
      };

      getListaProductos();
    });
  };

  const handleDeleteClick = (producto) => {
    setProductToDelete(producto.id);
    setOpenConfirmDialog(true);
  };

  const handleCuotaClick = (id) => {
    setProductToDelete(id);

    const getPayments = async () => {
      try {
        const response = await getPaymentInvoce(id);
        setProductSale(response.data);
        setOpenCuotaDialog(true);
      } catch (error) {
        console.error("Error fetching payments: ", error);
      }
    };

    getPayments();
  };

  const handleConfirmDelete = () => {
    deleteSell(productToDelete);
    setOpenConfirmDialog(false);
    setProductToDelete(null);
  };

  const handleConfirmCuotaExtra = () => {
   
    savePricePaymentExtra();
    setOpenCuotaDialog(false);
    setProductToDelete(null);
  };


  const savePricePaymentExtra = async () => {

    const fechaUTC = new Date(
      dateCuota.getTime() - dateCuota.getTimezoneOffset() * 60000
    ).toISOString();

    const payload = {
      invoceId : productToDelete,
      price: priceCuota,
      date: fechaUTC
  
     }
  
    try {
      savePaymentExtra(payload).then((response) => {
          setPriceCuota(0);  
          setOpenDiscount(false);  


          const printer = {
            paymentId: response.data,
            invoceId: productToDelete,
          };
          const listPdf = async () => {
            const res = await getCuotasPdf(printer);
            const file = new Blob([res.data], {
              type: "application/pdf",
            });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            window.open(fileURL);
          };
          listPdf();

        });        
        

    } catch (error) {
       
        dispatch({
            type: "OPEN_SNACKBAR",
            openMensaje: {
                open: true,
                mensaje: "No se pudo guardar",
            },
        });
    }
};

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
    setProductToDelete(null);
  };

  const handleCancelCuota = () => {
    setOpenCuotaDialog(false);
    setProductToDelete(null);
    setPriceCuota(0);
  };


  const [productsData, setProductsData] = useState({
    data: [],
  });

  useEffect(() => {
    const getListaProductos = async () => {
      const response = await getDiscountByAssociate(itemData.id);
      setProductsData(response);
    };

    getListaProductos();
  }, []);

  const paySelect = async () => {
    const selectedProductDetails = extractSelectedProductDetails();

    selectPay(selectedProductDetails).then((response) => {

      const printer = {
        id: itemData.id,
        invoce: response.data,
        dateDelivered: dateFrom
      };

      const listPdf = async () => {
        const res = await getPayPdf(printer);
        const file = new Blob([res.data], {
          type: "application/pdf",
        });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      };
      listPdf();
      addSelectionPay();
      setOpenDiscount(false);
    });
  }

  const extractSelectedProductDetails = () => {
    const productsArray = Object.values(selectedProducts);
    const extractedDetails = productsArray.map(({ id, isContribution }) => ({
      id,
      isContribution,
      dateDelivered: dateFrom
    }));

    return extractedDetails;
  }

  const handleSelectProduct = (producto, isChecked) => {
    const newSelectedProducts = { ...selectedProducts };

    if (isChecked) {
      newSelectedProducts[producto.id] = producto;
    } else {
      delete newSelectedProducts[producto.id];
    }

    setSelectedProducts(newSelectedProducts);
    calculateTotal(newSelectedProducts);
  };

  const calculateTotal = (selectedProducts) => {
    const newTotalPrice = Object.values(selectedProducts).reduce((acc, curr) => {
      return acc + curr.price;
    }, 0);

    setTotalPrice(newTotalPrice);
  };

  return (
    <>
      <Container>
        <Divider className={classes.divider} />
        <Typography variant="body2" className={classesMain.text_title}>
          Nombre: {itemData.lastName + " " + itemData.name}
        </Typography>
        <Typography variant="body2" className={classesMain.text_title}>
          Codigo : {itemData.code}
        </Typography>

        {/* <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
          <KeyboardDatePicker
            value={dateFrom}
            onChange={setDateFrom}
            margin="normal"
            fullWidth
            id="fecha-publicacion-id"
            label="Fecha de entrega"
            format="dd/MM/yyyy"
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </MuiPickersUtilsProvider> */}
        <Typography variant="h6" color="error">
          Total por pagar : {itemData.price}Bs
        </Typography>
        <Typography variant="h6">
          Total seleccionado: {totalPrice} Bs
        </Typography>
        <TextField
          label="Total recibido  "
          variant="outlined"
          className={classes.gridmb}
          InputLabelProps={{
            shrink: true,
          }}
          name="group"
          value={group}
          onChange={handleChangeGroup}
        />
        <Typography variant="h6" color="textSecondary">
          Cambio: {change} Bs
        </Typography>
        <Divider className={classes.divider} />
        <Button variant="contained" color="primary" onClick={paySelect}>
          PAGAR LO SELECCIONADO
        </Button>

        <Divider className={classes.divider} />
        <Typography variant="h6" className={classesMain.text_title}>
          Productos por pagar
        </Typography>

        <Divider className={classes.divider} />
        <TableContainer className={classesMain.gridmb}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>FECHA</TableCell>
                <TableCell>NOMBRE</TableCell>
                <TableCell>CANTIDAD</TableCell>
                <TableCell>TOTAL</TableCell>
                <TableCell>SELECCIONAR</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {productsData.data.map((producto) => (
                <TableRow key={producto.id}>
                  <TableCell>
                    <Typography className={classes.text_detalle}>
                      {producto.datePay}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.text_detalle}>
                      {producto.item}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.text_detalle}>
                      {producto.count}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.text_detalle}>
                      {producto.price} Bs
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      checked={selectedProducts.hasOwnProperty(producto.id)}
                      onChange={(event) => handleSelectProduct(producto, event.target.checked)}
                      color="primary"
                    />
                  </TableCell>
                  <TableCell>
                    {sesionUsuario.usuario.admin && !producto.isContribution &&
                      <Button variant="contained" color="secondary" onClick={() => handleDeleteClick(producto)}>
                        Eliminar
                      </Button>
                    }
                  </TableCell>
                  <TableCell>
                    {!producto.isContribution &&
                      <Button variant="contained" color="secondary" onClick={() => handleCuotaClick(producto.id)}>
                        Cuotas
                      </Button>
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>

      <Dialog
        open={openConfirmDialog}
        onClose={handleCancelDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirmar Eliminación</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Está seguro de que desea eliminar este producto?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleConfirmDelete} color="primary" autoFocus>
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openCuotaDialog}
        onClose={handleCancelCuota}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title">Detalles Cuota</DialogTitle>

        <DialogContent>
          {productSale && <>
            <Typography variant="h6" color="error">
              Total Costo : {productSale.price}Bs
            </Typography>
            <Typography variant="h6">
              Total pagado: {productSale.pay} Bs
            </Typography>
            <Typography variant="h6">
              Total deuda: {productSale.fine} Bs
            </Typography>
          </>
          }

          {productSale && productSale.pays.length > 0 ? (
            <>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Fecha de pago</TableCell>
                      <TableCell>Monto</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {productSale.pays.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>{row.datePay}</TableCell>
                        <TableCell>{row.price}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ) : (
            <Typography variant="h6" align="center">
              No hay cuotas
            </Typography>
          )}

          {/* <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <KeyboardDatePicker
              value={dateCuota}
              onChange={setDateCuota}
              margin="normal"
              fullWidth
              id="fecha-publicacion-id"
              label="Fecha pago cuota"
              format="dd/MM/yyyy"
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider> */}
          <br></br>
          <TextField
          label="Monto a pagar  "
          variant="outlined"
          className={classes.gridmb}
          InputLabelProps={{
            shrink: true,
          }}
          name="priceCuota"
          value={priceCuota}
          onChange={handleChangeCupo}
        />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelCuota} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleConfirmCuotaExtra} color="primary" autoFocus>
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Discount;
