import React, { useState } from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import MenuIcon from "@material-ui/icons/Menu";
import makeStyles from "../Product/styles";
import AllAssociate from "./AllAssociate";
import AllSale from "./AllSale";
import AllDebt from "./AllDebt";
import AllProduct from "./AllProduct";
import AllCartera from "./AllCartera";


const TabReport = ({ onDrawerToggle }) => {
  const classes = makeStyles();
  const [value, setValue] = useState(0);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  return (
    <React.Fragment>
      <AppBar color="primary" position="sticky" elevation={0}>
        <Toolbar>
          <Grid container spacing={1} alignItems="center">
            <Hidden smUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={onDrawerToggle}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>

            <Grid item>
              <Typography variant="h5" component="h2" align="center">
                REPORTES
              </Typography>
            </Grid>
            <Grid item xs />
            <Grid item>
              <IconButton color="inherit" className={classes.iconButtonAvatar}>
                <Avatar src="/static/images/avatar/1.jpg" alt="My Avatar" />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      <AppBar
        component="div"
        className={classes.secondaryBar}
        color="primary"
        position="static"
        elevation={0}
      >
        <Tabs value={value} onChange={handleChange} textColor="inherit">
          <Tab textColor="inherit" label="Socios" {...a11yProps(0)} />
          <Tab textColor="inherit" label="Ventas" {...a11yProps(1)} />
          <Tab textColor="inherit" label="Deudas" {...a11yProps(2)} />
          <Tab textColor="inherit" label="Productos" {...a11yProps(3)} />
          <Tab textColor="inherit" label="Cartera" {...a11yProps(4)} />
        </Tabs>
      </AppBar>

      <TabPanel value={value} index={0}>
        <AllAssociate></AllAssociate>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <AllSale></AllSale>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <AllDebt></AllDebt>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <AllProduct></AllProduct>
      </TabPanel>
      <TabPanel value={value} index={4}>
        <AllCartera></AllCartera>
      </TabPanel>
    </React.Fragment>
  );
};

export default TabReport;
